import React from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "../pages/SignIn";
import Dashboard from "../pages/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Login from "../pages/Login";
import ErrorBoundary from "../components/ErrorBoundary";
import Patients from "../pages/Patients";
import ApiConfigurations from "../pages/ApiConfigurations";
import PatientLogs from "../pages/PatientLogs";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <SignIn />
          </ErrorBoundary>
        }
      />
      <Route
        path="/login"
        element={
          <ErrorBoundary>
            <Login />
          </ErrorBoundary>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <ErrorBoundary>
              <Dashboard />
            </ErrorBoundary>
          </ProtectedRoute>
        }
      />
      <Route
        path="/patients"
        element={
          <ProtectedRoute>
            <ErrorBoundary>
              <Patients />
            </ErrorBoundary>
          </ProtectedRoute>
        }
      />
      <Route
        path="/api-configurations"
        element={
          <ProtectedRoute>
            <ErrorBoundary>
              <ApiConfigurations />
            </ErrorBoundary>
          </ProtectedRoute>
        }
      />
      <Route
        path="/logs"
        element={
          <ProtectedRoute>
            <ErrorBoundary>
              <PatientLogs />
            </ErrorBoundary>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
export default AppRoutes;
