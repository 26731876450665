import Template from "../components/Template";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Icon, Label, Modal, Popup } from "semantic-ui-react";
import { useActions } from "../redux/actions/UseActions";
import * as OpenDentalApiConfigActions from "../redux/actions/OpenDentalApiConfigActions";
import * as PracticeActions from "../redux/actions/PracticeActions";
import { PageRequest, PageResponse } from "../models/Pagination";
import moment from "moment";
import { OpenDentalApiConfig } from "../models/OpenDentalApiConfig";
import ApiConfigurationModal from "../components/ApiConfigurations/ApiConfigurationModal";
import { Practice } from "../models/Practice";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import { HelpMark } from "../components/help/HelpMark";
import { HELP_PAGE } from "../utils/HELP_PAGE";

const ApiConfigurations = () => {
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const openDentalApiConfigActions = useActions(OpenDentalApiConfigActions);
  const practiceActions = useActions(PracticeActions);
  const [pagedApiConfigs, setPagedOpenDentalApiConfigs] = useState<
    PageResponse<OpenDentalApiConfig>
  >({
    data: [],
    count: 0,
  });
  const [practices, setPractices] = useState<PageResponse<Practice>>({
    data: [],
    count: 0,
  });
  const [pageRequest, setPageRequest] = useState<PageRequest>({
    limit: itemsPerPage,
    offset: 0,
    where: {},
  });
  const { limit, offset } = pageRequest;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentConfig, setCurrentConfig] = useState({} as OpenDentalApiConfig);
  const [state, setState] = useState({
    openModal: false,
  });

  const closeModal = async () => {
    setState({ openModal: false });
    setCurrentConfig({} as OpenDentalApiConfig);
    setEditMode(false);
    await loadOpenDentalApiConfigs();
  };

  useEffect(() => {
    loadOpenDentalApiConfigs();
    loadPractices();
  }, []);

  useEffect(() => {
    loadOpenDentalApiConfigs();
  }, [pageRequest]);

  const loadOpenDentalApiConfigs = async () => {
    setLoading(true);
    const data: PageResponse<OpenDentalApiConfig> =
      await openDentalApiConfigActions.getOpenDentalApiConfigs({
        limit,
        offset,
        where: pageRequest.where,
      });
    data && setPagedOpenDentalApiConfigs(data);
    setLoading(false);
  };

  const loadPractices = async () => {
    setLoading(true);
    const data = await practiceActions.getOperatingPractices();
    data && setPractices(data);
    setLoading(false);
  };

  const onChangePage = (activePage: number, totalRows: number) => {
    const nextOffSet = calculateNextOffset(activePage, itemsPerPage);
    setPageRequest({ offset: nextOffSet, limit });
  };

  const handlePerRowsChange = async (rowPerPage: any, page: any) => {
    setLoading(true);
    setItemsPerPage(rowPerPage);
    setCurrentPage(0);
    setPageRequest({
      limit: rowPerPage,
      offset: calculateNextOffset(currentPage, itemsPerPage),
      where: {},
    });
    setLoading(false);
  };

  const calculateNextOffset = (currentPage: number, itemsPerPage: number) => {
    return itemsPerPage * (currentPage - 1);
  };

  const maskText = (text: string | any[]) => "*".repeat(text.length);

  const deleteConfiguration = async (id: number | undefined) => {
    setLoading(true);
    const response = await openDentalApiConfigActions.deleteConfiguration(id);
    if (response?.rowCount) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "The Api configuration has been deleted successfully.",
        showConfirmButton: false,
        timer: 4000,
        toast: true,
        background: "#105063",
        color: "white",
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title:
          "The deletion of a this Api configuration failed. Please verify whether a configuration for the that practice already exists.",
        showConfirmButton: false,
        timer: 6000,
        toast: true,
        background: "#932121",
        color: "white",
      });
    }
    setShowDeleteModal(false);
    setCurrentConfig({} as OpenDentalApiConfig);
    await loadOpenDentalApiConfigs();
    setLoading(false);
  };

  const revealDeleteModal = async (
    openDentalApiConfig: OpenDentalApiConfig,
  ) => {
    setCurrentConfig(openDentalApiConfig);
    setShowDeleteModal(true);
  };
  const editConfig = async (openDentalApiConfig: OpenDentalApiConfig) => {
    setCurrentConfig(openDentalApiConfig);
    setEditMode(true);
    setState({ openModal: true });
  };
  const [loading, setLoading] = useState(true);
  const [selectedPractice, setSelectedPractice] = useState("");

  const handleSelectInputChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: any,
  ) => {
    setSelectedPractice(data.value);
    setPageRequest({ ...pageRequest, where: { practiceId: data.value } });
  };

  let practiceOptions = practices?.data.map((practice, key) => ({
    text: practice.practice,
    value: practice.id,
    key,
  }));
  let availablePractices: Practice[] = [];
  if (practiceOptions) {
    if (practiceOptions[0]?.text !== "All Practices") {
      practiceOptions?.unshift({
        text: "All Practices",
        value: 0,
        key: 0,
      });
    }
    const existingConfigs = pagedApiConfigs?.data.map((config: any, i) => {
      return config.practice.id;
    });
    if (existingConfigs) {
      availablePractices = practices.data.filter(
        (pr: any) => !existingConfigs.includes(pr.id),
      );
    }
  }

  const columns = [
    {
      name: "Action",
      selector: (row: any) => {
        return (
          <Button.Group compact size="mini">
            <Popup
              content={`Delete ${row.practice?.practice}'s configuration.`}
              trigger={
                <Button basic icon onClick={() => revealDeleteModal(row)}>
                  <Icon name="trash alternate outline" className="mr10" />
                </Button>
              }
              inverted
            />
            <Popup
              inverted
              content={`Edit ${row.practice?.practice}'s config.`}
              trigger={
                <Button primary icon onClick={() => editConfig(row)}>
                  <Icon name="edit outline" className="mr10" />
                </Button>
              }
            />
          </Button.Group>
        );
      },
      sortable: false,
    },
    {
      name: "Practice",
      selector: (row: any) => row.practice?.practice,
      sortable: true,
    },
    {
      name: "Url",
      selector: (row: any) => row.url,
      sortable: true,
    },
    {
      name: "Authentication",
      selector: (row: any) => maskText(row.authentication),
      sortable: false,
    },
    {
      name: "Date Created",
      selector: (row: any) =>
        moment(row.createdAt).format("DD MMMM YYYY @HH:mm"),
      sortable: true,
    },
    {
      name: "Date Updated",
      selector: (row: any) =>
        moment(row.updatedAt).format("DD MMMM YYYY @HH:mm"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: any) => (row.error ? "Fail to Connect" : "Connected"),
      sortable: true,
      cell: (row: any) => (
        <Label
          className="cursorPointer"
          color={row.error ? "red" : "green"}
          title={row.error}
          content={row.error ? "Fail to Connect" : "Connected"}
        />
      ),
    },
  ];

  const manuallyRerunLambdaFunction = async () => {
    setLoading(true);
    const response = await openDentalApiConfigActions.manuallyRerunLambdaFunction();
    if (response && response.status === "Success") {
      setLoading(false);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Manual rerun was successful.",
        showConfirmButton: false,
        timer: 2000,
        toast: true,
        background: "#105063",
        color: "white",
      }).then(async () => {
        setTimeout(() => {
          Swal.fire({
            position: "top-end",
            icon: "info",
            title: "Loading data, please wait...",
            showConfirmButton: false,
            timer: 2000,
            toast: true,
            background: "#105063",
            color: "white",
          })
        }, 200);
        await loadOpenDentalApiConfigs();
      })
    } else {
      setLoading(false);
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Manual rerun failed!!",
        showConfirmButton: false,
        timer: 6000,
        toast: true,
        background: "#932121",
        color: "white",
      });
    }
    setLoading(false);
  }

  return (
    <Template activeLink="dashboard" loading={loading}>
      <div>
        <div className="ui card" style={{ padding: "10px", width: "100%" }}>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div>
              <h1>
                API Configurations ({pagedApiConfigs?.count}){" "}
                <HelpMark helpPage={HELP_PAGE.Api_Configuration} />
              </h1>
            </div>
            <div
              style={{
                float: "right",
                display: "flex",
                columnGap: "10px",
              }}
            >
              <Button
                  content="Manually Rerun"
                  primary
                  labelPosition="right"
                  icon="refresh"
                  onClick={() => manuallyRerunLambdaFunction()}
              />
              <Dropdown
                search={true}
                style={{minWidth: '100px'}}
                disabled={editMode}
                required
                placeholder="Select Practice"
                fluid
                selection
                options={practiceOptions}
                value={selectedPractice}
                onChange={(e, data) => handleSelectInputChange(e, data)}
              />
              <Button
                content="Add Configuration"
                secondary
                labelPosition="right"
                icon="plus"
                onClick={() => setState({ openModal: true })}
              />
            </div>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={pagedApiConfigs.data}
          progressPending={loading}
          onChangePage={onChangePage}
          selectableRows={false}
          responsive={true}
          defaultSortFieldId={7}
          defaultSortAsc={false}
          striped={true}
        />
        <div style={{ margin: "60px" }}></div>
      </div>
      <ApiConfigurationModal
        open={state.openModal}
        onClose={closeModal}
        practices={availablePractices}
        editMode={editMode}
        data={currentConfig}
      />
      <Modal
        size="small"
        open={showDeleteModal}
        closeIcon
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Header>Delete Api Configuration.</Modal.Header>
        <Modal.Content>
          <p>{`Are you sure you want to delete the api configuration for the practice: ${currentConfig.practice?.practice}`}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary negative onClick={() => setShowDeleteModal(false)}>
            No, Cancel
          </Button>
          <Button
            primary
            positive
            onClick={() => deleteConfiguration(currentConfig?.id)}
          >
            Yes, Proceed
          </Button>
        </Modal.Actions>
      </Modal>
    </Template>
  );
};

export default ApiConfigurations;
