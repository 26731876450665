export const getPractices =
  () => async (dispatch: Function, _getState: Function, api: any) => {
    try {
      return await api.get(`practices`);
    } catch (err) {
      console.error(err);
      return null;
    }
  };

export const getOperatingPractices =
    () => async (dispatch: Function, _getState: Function, api: any) => {
        try {
            return await api.get(`practices/udaPractices`);
        } catch (err) {
            console.error(err);
            return null;
        }
    };
