import { MenuItem } from "../models/MenuItem";

export const MENUS: Array<MenuItem> = [
  {
    name: "dashboard",
    icon: "dashboard",
    title: "Dashboard",
    subMenus: [],
    role: [],
  },
  {
    name: "api-configurations",
    icon: "settings",
    title: "Api Configurations",
    subMenus: [],
    role: [],
  },
  {
    name: "patients",
    icon: "users",
    title: "Patients",
    subMenus: [],
    role: [],
  },
  {
    name: "logs",
    icon: "tasks",
    title: "Sync Logs",
    subMenus: [],
    role: [],
  },
];
