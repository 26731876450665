import { convertToQueryParams } from "../../utils/converter";

export const getOpenDentalApiConfigs =
  ({ limit, offset, where }: any) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      return await api.get(
        `open-dental-api-configs?limit=${limit}&offset=${offset}&${convertToQueryParams(
          where,
        )}`,
      );
    } catch (err) {
      console.error(err);
      return null;
    }
  };

export const saveOpenDentalApiConfig =
  (data: any) => async (dispatch: Function, _getState: Function, api: any) => {
    try {
      return await api.post(`open-dental-api-configs`, data);
    } catch (err) {
      console.error(err);
      return null;
    }
  };

export const deleteConfiguration =
  (id: number) => async (dispatch: Function, _getState: Function, api: any) => {
    try {
      return await api.delete(`open-dental-api-configs/${id}`);
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

export const updateConfiguration =
  (id: number, data: any) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      const result = api.patch(`open-dental-api-configs/${id}`, data);
      console.log("updateConfiguration:: result", result);
      return result;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };


export const manuallyRerunLambdaFunction =
    (id: number) => async (dispatch: Function, _getState: Function, api: any) => {
        try {
            return await api.get(`lambdas/manuallyRerunLambdaFunction`);
        } catch (error) {
            console.error(error);
            return 0;
        }
    };