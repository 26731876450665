import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import { createLogger } from "redux-logger";
import { ActionType } from "../models/ActionType";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import { API_URL } from "../utils/constants";
import { Api } from "./Api";
import rootReducer from "./reducers/RootReducers";
import thunk from "redux-thunk";
import * as localforage from "localforage";

const actionTypeEnumToString = (action: any): any => {
  if (
    action?.type !== null &&
    action?.type !== undefined &&
    typeof action.type === "number" &&
    ActionType[action.type]
  ) {
    return {
      type: ActionType[action.type],
      payload: action.payload,
    };
  }
  return action;
};

const persistConfig: PersistConfig<any> = {
  key: "root",
  version: 1,
  storage: localforage,
  whitelist: ["authReducer"],
};

const logger = createLogger({ actionTransformer: actionTypeEnumToString });
const composeEnhancers = composeWithDevTools({
  actionSanitizer: actionTypeEnumToString,
});

const history = createBrowserHistory();
const isDevelopment = process.env.NODE_ENV === "development";
const initialState = {};
const api = new Api(API_URL);

const middleware = [thunk.withExtraArgument(api)];

if (isDevelopment) {
  middleware.push(logger);
}

const enhancedDevMW = isDevelopment
  ? composeEnhancers(applyMiddleware(...middleware))
  : applyMiddleware(...middleware);

const _persistedReducer = persistReducer(persistConfig, rootReducer());

const configureStore = () => {
  const store = createStore(_persistedReducer, initialState, enhancedDevMW);
  const _persistStore = persistStore(store);
  return { store, _persistStore };
};

export default configureStore;
export { history };
